import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

export function trackInteraction({ category, type, action, additionalValues = {} }) {
  pushToDataLayer({
    event: 'interaction',
    metadata: {
      event: {
        interaction: {
          category,
          type,
          action,
          ...additionalValues,
        }
      }
    }
  })
}

export function trackFilterInteractionMultiValue({ name, value, inputType, selected }) {
  trackFilterInteraction({
    name,
    value,
    inputType,
    categorySpecificValues: {
      filter_selected: selected,
    }
  })
}

export function trackFilterInteraction({ name, value, inputType, categorySpecificValues = {} }) {
  pushToDataLayer({
    event: 'filtered',
    metadata: {
      event: {
        filter: {
          filter_name: name,
          filter_value: value,
          filter_category: inputType,
          ...categorySpecificValues,
        }
      }
    }
  })
}

export function useTrackCurrentFiltersAndQuery(filtersAndValues, totalcount, filterCategory, query = undefined) {
  React.useEffect(
    () => {
      pushToDataLayer({
        metadata: {
          ...(query && { query }),
          filters: Object.values(filtersAndValues)
            .flatMap(({ id, options, value }) =>
              options.map(x => ({
                filter_name: id,
                filter_value: x.id,
                filter_selected: value.includes(x.id),
                filter_category: filterCategory
              }))
            ),
          filters_totalcount: totalcount
        }
      })
    },
    [filtersAndValues, totalcount, filterCategory, query]
  )
}
