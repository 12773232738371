import { trackInteraction } from '/analytics/user-interaction'

const portalCallback = { current: null }

export function useOnShowSkillsMatchPortalFlow(callback) {
  portalCallback.current = callback
}

export function useShowSkillsMatchPortalFlow() {
  return (
    React.useCallback(() => {
      portalCallback.current()
      trackInteraction({
        category: 'skillsmatch-portal',
        type: 'click',
        action: 'skillsmatch portal opened'
      })
    }, [])
  )
}
